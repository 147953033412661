// lista kategorii

import React, { FC, useCallback } from 'react';
import classnames from 'classnames';

import { ICategoryListItem, ICategory } from 'api/types';
import { Link } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/Categories/Categories.module.scss';

// typ danych wejściowych
interface Props {
  onCategoryClick: () => void;
  categories?: ICategory[];
  mainMenuPositions?: ICategory[];
  columnsCount?: number;
  chosenCategoryId?: number;
  searchKeyword?: string;
}

const Categories: FC<Props> = ({ onCategoryClick, categories, mainMenuPositions }) => {
  const renderMainMenuPositions = useCallback((category: ICategoryListItem) => {
    return (
      <div className={classnames(styles.firstLevel)}>
        <Link onClick={() => onCategoryClick()} to={`/${category.url_link}`}>
          {category.name}
        </Link>
      </div>
    );
  }, []);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Layouts-MainLayout-Components-Categories'
      )}>
      {mainMenuPositions?.map((category, i) => (
        <div
          key={i}
          className={classnames(styles.firstLevel, {
            [styles.news]: category.style === 'orange_style',
            [styles.promotion]: category.style === 'red_style'
          })}>
          <Link onClick={() => onCategoryClick()} to={`/${category.url_link}`}>
            {category.label}
          </Link>
        </div>
      ))}
      {categories?.map((category, i) => (
        <div key={i}>
          {category.categories_list?.map((category) => renderMainMenuPositions(category))}
        </div>
      ))}
    </div>
  );
};

export default Categories;
